exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-industries-banking-finance-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/builds/megamtech-labs/mebot/industries/banking_finance/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-industries-banking-finance-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-industries-bussinness-process-outsourcing-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/builds/megamtech-labs/mebot/industries/bussinness-process-outsourcing/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-industries-bussinness-process-outsourcing-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-industries-education-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/builds/megamtech-labs/mebot/industries/Education/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-industries-education-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-industries-healthcare-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/builds/megamtech-labs/mebot/industries/healthcare/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-industries-healthcare-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-industries-humanresource-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/builds/megamtech-labs/mebot/industries/humanresource/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-industries-humanresource-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-industries-logistics-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/builds/megamtech-labs/mebot/industries/logistics/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-industries-logistics-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-industries-manufacturing-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/builds/megamtech-labs/mebot/industries/manufacturing/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-industries-manufacturing-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-industries-retail-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/builds/megamtech-labs/mebot/industries/retail/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-industries-retail-index-mdx" */),
  "component---src-pages-blog-post-js": () => import("./../../../src/pages/blog-post.js" /* webpackChunkName: "component---src-pages-blog-post-js" */),
  "component---src-pages-discover-more-js": () => import("./../../../src/pages/discover-more.js" /* webpackChunkName: "component---src-pages-discover-more-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industries-mdx-frontmatter-slug-js-content-file-path-industries-banking-finance-index-mdx": () => import("./../../../src/pages/industries/{mdx.frontmatter__slug}.js?__contentFilePath=/builds/megamtech-labs/mebot/industries/banking_finance/index.mdx" /* webpackChunkName: "component---src-pages-industries-mdx-frontmatter-slug-js-content-file-path-industries-banking-finance-index-mdx" */),
  "component---src-pages-industries-mdx-frontmatter-slug-js-content-file-path-industries-bussinness-process-outsourcing-index-mdx": () => import("./../../../src/pages/industries/{mdx.frontmatter__slug}.js?__contentFilePath=/builds/megamtech-labs/mebot/industries/bussinness-process-outsourcing/index.mdx" /* webpackChunkName: "component---src-pages-industries-mdx-frontmatter-slug-js-content-file-path-industries-bussinness-process-outsourcing-index-mdx" */),
  "component---src-pages-industries-mdx-frontmatter-slug-js-content-file-path-industries-education-index-mdx": () => import("./../../../src/pages/industries/{mdx.frontmatter__slug}.js?__contentFilePath=/builds/megamtech-labs/mebot/industries/Education/index.mdx" /* webpackChunkName: "component---src-pages-industries-mdx-frontmatter-slug-js-content-file-path-industries-education-index-mdx" */),
  "component---src-pages-industries-mdx-frontmatter-slug-js-content-file-path-industries-healthcare-index-mdx": () => import("./../../../src/pages/industries/{mdx.frontmatter__slug}.js?__contentFilePath=/builds/megamtech-labs/mebot/industries/healthcare/index.mdx" /* webpackChunkName: "component---src-pages-industries-mdx-frontmatter-slug-js-content-file-path-industries-healthcare-index-mdx" */),
  "component---src-pages-industries-mdx-frontmatter-slug-js-content-file-path-industries-humanresource-index-mdx": () => import("./../../../src/pages/industries/{mdx.frontmatter__slug}.js?__contentFilePath=/builds/megamtech-labs/mebot/industries/humanresource/index.mdx" /* webpackChunkName: "component---src-pages-industries-mdx-frontmatter-slug-js-content-file-path-industries-humanresource-index-mdx" */),
  "component---src-pages-industries-mdx-frontmatter-slug-js-content-file-path-industries-logistics-index-mdx": () => import("./../../../src/pages/industries/{mdx.frontmatter__slug}.js?__contentFilePath=/builds/megamtech-labs/mebot/industries/logistics/index.mdx" /* webpackChunkName: "component---src-pages-industries-mdx-frontmatter-slug-js-content-file-path-industries-logistics-index-mdx" */),
  "component---src-pages-industries-mdx-frontmatter-slug-js-content-file-path-industries-manufacturing-index-mdx": () => import("./../../../src/pages/industries/{mdx.frontmatter__slug}.js?__contentFilePath=/builds/megamtech-labs/mebot/industries/manufacturing/index.mdx" /* webpackChunkName: "component---src-pages-industries-mdx-frontmatter-slug-js-content-file-path-industries-manufacturing-index-mdx" */),
  "component---src-pages-industries-mdx-frontmatter-slug-js-content-file-path-industries-retail-index-mdx": () => import("./../../../src/pages/industries/{mdx.frontmatter__slug}.js?__contentFilePath=/builds/megamtech-labs/mebot/industries/retail/index.mdx" /* webpackChunkName: "component---src-pages-industries-mdx-frontmatter-slug-js-content-file-path-industries-retail-index-mdx" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-releases-js": () => import("./../../../src/pages/releases.js" /* webpackChunkName: "component---src-pages-releases-js" */),
  "component---src-pages-rpa-js": () => import("./../../../src/pages/rpa.js" /* webpackChunkName: "component---src-pages-rpa-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */)
}

